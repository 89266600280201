import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.styl'],
})
export class DownloadsComponent implements OnInit {
  downloads: any;

  constructor(
    private header: HeaderService,
    private contentful: ContentfulService
  ) {}

  ngOnInit(): void {
    this.contentful.getCollectionByName('downloads', 2).then((downloads) => {
      console.log(downloads);
      this.downloads = downloads.fields.downloads;
      console.log(this.downloads);
    });

    this.header.setHeaderClasses('small');
  }
}
