<main *transloco="let t;">
  <header>
    <div class="line"></div>
    <h1>
      {{ t('navigation.about') }}
    </h1>
    <h2>
      {{ t('about.pagetitle') }}
    </h2>
  </header>
  <article>
    <section id="about">
      <div class="col">
        <div class="text">
          <h2>
            {{ t('about.about.title') }}
          </h2>
          <p>
            {{ t('about.about.paragraph1') }}
          </p>
          <p>
            {{ t('about.about.paragraph2') }}
          </p>
          <p>
            {{ t('about.about.paragraph3') }}
          </p>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/platz4.jpg" alt="">
        </div>
      </div>
    </section>
    <section id="cv">
      <div class="col full title">
        <div class="text">
          <h2>
            {{ t('about.management.title') }}
          </h2>
      </div>
      </div>
      <div class="col">
        <div class="text">
          <p>
            {{ t('about.management.paragraph1') }}
          </p>
          <p>
            {{ t('about.management.paragraph2') }}
          </p>
          <div class="cv">
            <div class="raphi">
              <img src="assets/img/gassmann.png" alt="">
              <div class="links">
                <a href="" class="link">
                  <img src="assets/img/icons/xing_mint.svg" alt="">
                </a>
                <a href="" class="link">
                  <img src="assets/img/icons/linkedin_mint.svg" alt="">
                </a>
                <a href="" class="link">
                  <img src="assets/img/icons/email_mint.svg" alt="">
                </a>
              </div>
            </div>
            <p>
              <b class="mint">
                {{ t('about.management.cv.title') }}
              </b>
            </p>
            <p>
              <b>{{ t('about.management.cv.professional.title') }}</b>
            </p>
            <p>
              <span class="year">
                {{ t('about.management.cv.professional.since') }} 2009
              </span>
              <span class="activity">
                {{ t('about.management.cv.professional.item1') }}
              </span>
            </p>
            <p>
              <span class="year">
                2007—2008
              </span>
              <span class="activity">
                {{ t('about.management.cv.professional.item2') }}
              </span>
            </p>
            <p>
              <span class="year">
                2001—2007
              </span>
              <span class="activity">
                {{ t('about.management.cv.professional.item3') }}
              </span>
            </p>
            <p>
              <span class="year">
                2000—2001
              </span>
              <span class="activity">
                {{ t('about.management.cv.professional.item4') }}
              </span>
            </p>
            <p>
              <span class="year">
                1995—1999
              </span>
              <span class="activity">
               {{ t('about.management.cv.professional.item5') }}
              </span>
            </p>
            <p>
              <b>{{ t('about.management.cv.education.title') }}</b>
            </p>
            <p>
              {{ t('about.management.cv.education.item1') }}
            </p>
            <p>
              {{ t('about.management.cv.education.item2') }}
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="text">
          <p>
            {{ t('about.management.paragraph3') }}
          </p>
        </div>
      </div>
    </section>
    <section class="banner quote">
      <h2 class="quote">
        {{ t('about.quote') }}
      </h2>
    </section>
    <section id="references">
      <div class="col full title">
        <div class="text">
          <h2>
            {{ t('about.clients.title') }}
          </h2>
        </div>
      </div>
      <div class="col">
        <div class="text">
        <p>
          {{ t('about.clients.paragraph1') }}
        </p>
        </div>
      </div>
      <div class="col">
        <div class="text">
          <p>
            {{ t('about.clients.paragraph2') }}
          </p>
        </div>
      </div>
      <div class="col full">
        <div class="text">
          <div class="references">
            <div class="reference" *ngFor="let reference of references">
              <a [href]="reference.fields.url">
                <img [src]="reference.fields.logo.fields.file.url" alt="">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="light-gray">
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_home_contact.jpg" alt="">
        </div>
      </div>
      <div class="col">
        <div class="text">
          <h2>
            Kontakt
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis dolore odit enim blanditiis, eius dolores quidem quasi tempore rem. Quibusdam velit necessitatibus quas. Quasi architecto ipsam temporibus facilis rem voluptate.
          </p>
          <div class="button" routerLink="/contact">
            <div class="button-text">
              <div class="one">
                Kontakt
              </div>
              <div class="two">
                Kontakt
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</main>
