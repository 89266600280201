import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.styl'],
})
export class AboutComponent implements OnInit {
  references: any;
  constructor(
    private contentful: ContentfulService,
    private header: HeaderService
  ) {}

  ngOnInit(): void {
    this.header.setHeaderClasses('mint small');
    this.contentful.getCollectionByName('references').then((references) => {
      this.references = references.fields.references;
      console.log(this.references);
    });
  }
}
