import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.styl'],
})
export class ServicesComponent implements OnInit {
  constructor(private header: HeaderService) {}

  epadDetailOpen = false;

  ngOnInit(): void {
    this.header.setHeaderClasses('mint small');
  }

  openEpadDetail() {
    this.epadDetailOpen = true;
    console.log('clicked');
  }
  closeEpadDetail() {
    this.epadDetailOpen = true;
  }
}
