import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.styl'],
})
export class SoftwareComponent implements OnInit {
  constructor(private header: HeaderService) {}

  ngOnInit(): void {
    this.header.setHeaderClasses('mint small');
  }
}
