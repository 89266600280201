<main *transloco="let t;">
  <header class="splash">
    <div class="background-image">
      <img src="assets/img/segetis_home_splash.jpg" alt="">
    </div>
    <div class="text" *transloco="let t;">
      <h1>
        {{ t('home.splash.h1') }}
      </h1>
      <h2>
        {{ t('home.splash.p') }}
      </h2>
      <!-- <a class="button" (click)="scrollTo('services')">
        <div class="text">
          <div class="one">
            jetzt entdecken
          </div>
          <div class="two">
            jetzt entdecken
          </div>
        </div>
      </a> -->
    </div>
  </header>
  <section class="banner" id="references" *ngIf="references" >
    <p *transloco="let t;">
      {{ t('home.references.clients') }}
    </p>
    <ng-container  *ngFor="let reference of references">
      <a class="reference" *ngIf="reference.fields.url" [href]="reference.fields.url" target="_blank" rel="noopener">
        <img [src]="reference.fields.logo.fields.file.url" alt="">
      </a>
      <div class="reference" *ngIf="!reference.fields.url">
        <img [src]="reference.fields.logo.fields.file.url" alt="">
      </div>
    </ng-container >
  </section>
  <section id="services">
    <div class="background-image">
      <img src="assets/img/segetis_home_services.jpg" alt="">
    </div>
    <div class="text">
      <h2>
        {{ t('navigation.services') }}
      </h2>
      <h3>
        {{ t('home.services.title') }}
      </h3>
      <p>
        {{ t('home.services.text') }}
      </p>
      <div class="button" routerLink="/services">
        <div class="text">
          <div class="one">
            {{ t('home.services.button.1') }}
          </div>
          <div class="two">
            {{ t('home.services.button.2') }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="attributes">
    <div class="card">
      <div class="image">
        <img src="assets/img/segetis_flexible.jpg" alt="">
      </div>
      <div class="text-container">
      <h3>
        {{ t('home.attributes.1.title') }}
      </h3>
      <p>
        {{ t('home.attributes.1.text') }}
      </p>
      </div>
    </div>
    <div class="card">
      <div class="text-container">
      <h3>
        {{ t('home.attributes.2.title') }}
      </h3>
      <p>
        {{ t('home.attributes.2.text') }}
      </p>
      </div>
      <div class="image">
        <img src="assets/img/segetis_sustainable.jpg" alt="">
      </div>
    </div>
    <div class="card">
      <div class="image">
        <img src="assets/img/segetis_responsible.jpg" alt="">
      </div>
      <div class="text-container">
      <h3>
        {{ t('home.attributes.3.title') }}
      </h3>
      <p>
        {{ t('home.attributes.3.text') }}
      </p>
    </div>
    </div>
  </section>
  <section id="software">
    <div class="background-image">
      <img src="assets/img/segetis_home_software.jpg" alt="">
    </div>
    <div class="text right-aligned">
      <h2>
        {{ t('navigation.software') }}
      </h2>
      <h3>
        {{ t('home.software.title') }}
      </h3>
      <p>
        {{ t('home.software.text') }}
      </p>
      <div class="button" routerLink="/software">
        <div class="text">
          <div class="one">
            {{ t('home.software.button.1') }}
          </div>
          <div class="two">
            {{ t('home.software.button.2') }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="about">
    <div class="background-image">
      <img src="assets/img/segetis_home_about.jpg" alt="">
    </div>
    <div class="text">
     <h2>
        {{ t('navigation.about') }}
      </h2>
      <h3>
        {{ t('home.about.title') }}
      </h3>
      <p>
        {{ t('home.about.text') }}
      </p>
      <div class="button" routerLink="/about">
        <div class="text">
          <div class="one">
            {{ t('home.about.button.1') }}
          </div>
          <div class="two">
            {{ t('home.about.button.2') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
