import { Injectable } from '@angular/core';
import { createClient, Asset, EntryCollection, Entry } from 'contentful';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private contentful = createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.apiKey,
  });

  constructor() {}

  getCollectionByName(name: string, levels?: number) {
    var id: string = environment.contentful.collectionIds[name];
    if (levels) {
      return this.contentful
        .getEntry(id, {
          include: levels,
        })
        .then((entry: Entry<any>) => {
          return entry;
        });
    } else {
      return this.contentful.getEntry(id).then((entry: Entry<any>) => {
        return entry;
      });
    }
  }

  getMediaUrlById(id: string, width?: number, height?: number) {
    return this.contentful.getAsset(id).then((asset: Asset) => {
      let url = asset.fields.file.url;
      return url;
    });
  }

  getCollectionByType(type: string) {
    return this.contentful.getContentType(type).then((entries: any) => {
      return entries;
    });
  }
}
