import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.styl'],
})
export class DataComponent implements OnInit {
  constructor(private header: HeaderService) {}

  ngOnInit(): void {
    this.header.setHeaderClasses('small');
  }
}
