import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.styl'],
})
export class HomeComponent implements OnInit {
  constructor(
    public contentful: ContentfulService,
    private viewportScroller: ViewportScroller,
    private header: HeaderService
  ) {}

  references: any;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    if (scrollOffset >= 240) {
      this.header.setHeaderClasses('small');
    } else {
      this.header.setHeaderClasses('');
    }
  }

  ngOnInit(): void {
    this.header.setHeaderClasses('');
    this.contentful.getCollectionByName('home_references').then((entry) => {
      this.references = entry.fields.references;
      console.log(this.references);
    });
  }

  scrollTo(id: string) {
    this.viewportScroller.scrollToAnchor(id);
  }
}
