<main>
  <header>
    <div class="line"></div>
    <h1>
      Services
    </h1>
    <h2>
      Smarte Lösungen für individuelle Bedürfnisse
    </h2>
  </header>
  <article>
    <section>
      <div class="col">
        <div class="text">
          <h2>
            Aktienregister-
            management
          </h2>
          <p>
            Die Grundlage eines jeden Aktienbuches sind die Erfassung, Pflege und Bearbeitung der Aktionärs­stamm­daten. Doch wir verwalten nicht nur die Daten Ihrer Aktionäre, machen Ein- und Austragungen und bedienen die Banken. Unsere Dienst­leistungen umfassen auch ein ausgereiftes Reporting.
          </p>
          <p>
            Wir wissen, welche Informationen für Sie und Ihr Unternehmen wichtig sind. Darum ist auch die systema­tische Auswertung der Aktionärsdaten ein wichtiger Bestandteil unserer Dienstleistung. Relevante Verände­rungen im Aktionariat werden Ihnen mit dem Sharelog Alert-Dienst umgehend mitgeteilt.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_services_share_register_management.jpg" alt="">
        </div>
      </div>

    </section>
    <section id="ga">
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_services_general_assembly.jpg" alt="">
        </div>
        <div class="text mint">
          <h4>
            Smarter duch Daten
          </h4>
          <p>
            Wir wissen, welche Informationen für Sie und Ihr Unternehmen wichtig sind. Darum ist auch die systema­tische Auswertung der Aktionärsdaten ein wichtiger Bestandteil unserer Dienstleistung. Relevante Verände­rungen im Aktionariat werden Ihnen mit dem Sharelog Alert-Dienst umgehend mitgeteilt.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="text">
          <h2>
            General-
            versammlung
          </h2>
          <p>
            Die Generalversammlung ist eine besondere Disziplin mit strengen rechtlichen Vorgaben, festen Abläufen und speziellen Regeln. Unsere Lösung erlaubt es Ihnen, jede Generalversammlung individuell auf höchstem Niveau vorzubereiten und durchzuführen.
          </p>
          <p>
            Die Vorbereitung zur Generalversammlung ist eng mit dem Aktienregister verknüpft. <b>Wir betreuen den Kunden während der gesamten Vorbereitung:</b>
          </p>
          <ul>
            <li>
              Unterstützung bei der Gestaltung der Drucksachen
            </li>
            <li>
              Vorbereitung und Versand der GV-Einladungen
            </li>
            <li>
              netVote: Die Aktionärinnen und Aktionäre können elektronisch fernabstimmen
            </li>
            <li>
              Bearbeitung der Rückantworten
            </li>
            <li>
              Regelmässiges Reporting über den Anmeldestand
            </li>
            <li>
              Erstellung und Versand der GV-Eintrittskarten
            </li>
            <li>
              Aufbereitung der Vollmachten und Instruktionen an die Stimmrechtsvertreter
            </li>
            <li>
              Regelmässige Konsistenzprüfung betreffend Anzahl stimmberechtigter Aktien
            </li>
          </ul>
          <p>
            <b>Wir unterstützen Sie am Tag der Generalversammlung:</b>
          </p>
          <ul>
            <li>
              Unterstützung bei der Gestaltung der Drucksachen
            </li>
            <li>
              Validierung der GV-Eintrittskarte
            </li>
            <li>
              Detailliertes Teilnehmerverzeichnis
            </li>
            <li>
              Laufende Präsenzerfassung
            </li>
            <li>
              Schnelle Ermittlung und Projektion der Abstimmungsergebnisse
            </li>
          </ul>
        </div>
      </div>
      <div class="col full" id="epad">
        <div class="text-container">
          <h4>
            Abstimmung via Funk
          </h4>
          <p>
            Die Abstimmung per Funk stellt besonders hohe An­forderungen an die Technik. Der ePad ist die sichtbare Einheit des komplexen Abstimmungssystems. Mit dem handlichen Abstimmungsgerät können Aktion­äre ihre Ent­scheidungen elektronisch übermitteln und die Ergebnisse werden innert wenigen Sekunden prä­sentiert.
          </p>
        </div>
        <div class="image mirror">
          <img src="assets/img/epad_3.png" alt="">
        </div>
        <div class="circle-button" (click)="epadDetailOpen? epadDetailOpen = false : epadDetailOpen = true" [class.close]="epadDetailOpen">
          <div class="icon">
            <div class="line one"></div>
            <div class="line two"></div>
          </div>
        </div>
        <div class="popup" [class.open]="epadDetailOpen">
          <p>
            <b>Antenneneinheit</b>
          </p>
          <p>
            Frequenz: 433,92 MHz oder 868 MHz<br>
Stimmenübertrag pro Sekunde: 400 Max<br>
Abstand für Stimmenübertrag: 100 m
          </p>
          <p>
            <b>Keyboard</b>
          </p>
          <p>
            3 farbige Tasten mit Backlight, um Wahlgänge in dunklen Räumen zu ermöglichen<br>
Funktionstaste: INFO
          </p>
          <p>
            <b>Gewicht</b>
          </p>
          <p>
            100 g (1 Batterie), 125 g (2 Batterien)
          </p>

        </div>
      </div>
    </section>
    <section class="image-banner">
      <div class="image">
        <img src="assets/img/jonas-jacobsson-2xaF4TbjXT0-unsplash.jpg" alt="">
      </div>
      <h2>
        Pandemie und Generalversammlung?
      </h2>
    </section>
    <section id="virtual-ga" *transloco="let t; read: 'services.virtual-general-assembly'">
      <div class="col">
        <div class="text">
          <h2 [innerHTML]="t('title')"></h2>
          <p>
            {{ t('paragraph1')}}          </p>
          <p>
            {{ t('paragraph2')}}          </p>
          <p>
            <b class="mint">{{ t('subtitle')}}</b>
          </p>
          <p>
            {{ t('paragraph3')}}          </p>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_services_virtual_general_assembly.jpg" alt="">
        </div>
        <div class="image">
          <img src="assets/img/segetis_services_mixed_general_assembly.jpg" alt="">
        </div>
      </div>
    </section>
    <section class="banner cta">
      <div class="col">
        <h2>Get in touch</h2>
      </div>
      <div class="col">
        <a href="tel: +41 041 541 91 00">+41 041 541 91 00</a>
      </div>
      <div class="col">
        <a href="mailto: info@segetis.ch">info@segetis.ch</a>
      </div>
    </section>
    <section>
      <div class="col image">
        <img src="assets/img/210114_laptop-mockup_1.jpg" alt="">
      </div>
      <div class="col">
        <div class="text">
          <h2>
            Software
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, at. Esse aut excepturi nam voluptate, dolores neque soluta praesentium minus dolorem dignissimos magnam molestias dolor nihil. Sunt sint eos ipsa!
          </p>
          <div class="button" routerLink="/software">
            <div class="button-text">
              <div class="one">
                Unsere Software
              </div>
              <div class="two">
                entdecken
              </div>
            </div>

          </div>
      </div>
      </div>
    </section>
  </article>
</main>
