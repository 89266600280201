<main>
  <h2>
    Links
  </h2>
  <div class="links">
    <ng-container *ngFor="let link of links">
    <div class="link" *ngIf="link.sys.contentType.sys.id == 'link'">
      <a [href]="link.fields.url" target="_blank" rel="noopener">
        {{ link.fields.name }}
      </a>
    </div>
    <div class="link-group" *ngIf="link.sys.contentType.sys.id == 'linkGroup'">
        <h3>
          {{ link.fields.name }}
        </h3>
        <div class="link" *ngFor="let l of link.fields.links">
          <a [href]="l.fields.url" target="_blank" rel="noopener">
            {{ l.fields.name }}
          </a>
        </div>
    </div>
  </ng-container>
  </div>
</main>