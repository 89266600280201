<main>
  <h2>
    Downloads
  </h2>
  <div class="links">
    <ng-container *ngFor="let download of downloads">
    <div class="link" *ngIf="download.sys.contentType.sys.id == 'download'">
      <a [href]="download.fields.media.fields.file.url" target="_blank" rel="noopener">
        {{ download.fields.name }}
      </a>
    </div>
    <div class="link-group" *ngIf="download.sys.contentType.sys.id == 'downloadGroup'">
        <h3>
          {{ download.fields.name }}
        </h3>
        <div class="link" *ngFor="let d of download.fields.downloads">
          <a [href]="d.fields.media.fields.file.url" target="_blank" rel="noopener">
            {{ d.fields.name }}
          </a>
        </div>
    </div>
  </ng-container>
  </div>
</main>