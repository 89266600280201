<main>
  <header>
    <div class="line"></div>
    <h1>
      Software
    </h1>
    <h2>
      Technische Innovation für hohe Ansprüche
    </h2>
  </header>
  <article>
    <section>
      <div class="col">
        <div class="text">
          <h2>
            Sharelog
          </h2>
          <p>
            Mit Sharelog hat Segetis eine leistungs­fähige Aktienregistersoftware geschaffen, die auf mo­derns­ten technischen Standards basiert und perma­nent weiterentwickelt wird. Sämtliche Module der Software sind vollständig integriert und erlauben ein schnitt­stellenfreies Arbeiten.
          </p>
          <p>
            Sharelog verfügt über eine elektronische Verbindung zu SIX SIS und unterstützt AREG-Data.
          </p>
          <p>
            Massgeschneiderte und flexible Outsourcinglösungen für die Führung eines modernen Aktienregisters sind mit dieser Software genauso möglich wie die firmeninterne Anwendung.
          </p>
          <p>
            Die Lizenzierung von Sharelog ermöglicht Ihnen den eigenverantwortlichen Betrieb und die Führung des Aktienregisters im eigenen Haus. Die notwendige Schnittstelle zur SIX SIS ist implementiert.
          </p>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_software_sharelog.jpg" alt="">
        </div>
      </div>
    </section>
    <section>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_software_sharelog_cockpit.jpg" alt="">
        </div>
      </div>
      <div class="col" *transloco="let t; read 'software.cockpit'">
        <div class="text">
          <h2>
            {{ t('title') }}
          </h2>
          <p>
            {{ t('paragraph1') }}
          </p>
          <p>
            {{ t('paragraph2') }}
          </p>
        </div>
      </div>

    </section>
    <section class="banner quote">
      <h2 class="quote">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus laboriosam quia.
      </h2>
      <div class="author">
        Hans Mustermann, CEO Mustermann AG
      </div>
    </section>
    <section id="netvote">
      <div class="col">
        <div class="text">
          <h2>
            Netvote
          </h2>
          <p>
            <b class="mint">Abstimmung für digitale Generalversammlungen</b>
          </p>
          <p>
            NetVote von Segetis mit der Nevis Sicherheitslösung von AdNovum Informatik AG ist das umfassende Paket zur elektronischen Fernabstimmung. Das Internet Proxy Voting System kann bei Interesse in den bestehenden Internet-Auftritt der Gesellschaft eingebunden und im Corporate Design des Unternehmens präsentiert werden. Über eine Webseite greift der Aktionär mit seiner Identifikation und einer individuellen Zugangsnummer (PIN) auf die Anwendung netVote zu.
          </p>
          <p>
            Der Aktionär kann elektronisch eine Zutrittskarte bestellen oder dem unabhängigen Stimmrechtsvertreter online Stimminstruktionen erteilen. Die Stimminstruktionen können sogar bis zum Tag der Generalversammlung abgeändert werden.          </p>
          <p>
            NetVote bietet den Aktionären dank seiner einfachen und intuitiven Bedienung einen hohen Komfort und erfüllt die Anforderungen der Minder-Initiative.
          </p>
          <p>
            Authentizität und Integrität aller Daten und Dokumente sind garantiert. Dank einer detaillierten Protokollierung können alle Aktivitäten der Aktionäre verfolgt werden. Der unabhängige Stimmrechtsvertreter kann zum Beispiel nachvollziehen, ob ein Aktionär ein Dokument nur gelesen hat oder ob er auch Stimminstruktionen geändert hat.
          </p>
          <p>
            <a routerLink="/services">
              mehr zur Virtuellen Generalversamlmung
            </a>
          </p>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_software_netvote_1.jpg" alt="">
        </div>
        <div class="image">
          <img src="assets/img/segetis_software_netvote_2.jpg" alt="">
        </div>
      </div>
    </section>
    <section>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_software_user_dashboard.jpg" alt="">
        </div>
      </div>
      <div class="col">
        <div class="text">
          <h2>
            User Dashboard
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis, consequatur enim facilis quae hic natus consequuntur. Iste esse, veritatis nihil, dicta qui illum ipsam iure alias rerum exercitationem sit facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum delectus cumque odit quis magnam enim veniam beatae inventore distinctio aut dolore deserunt, fuga perferendis, eveniet molestias consectetur vero eaque. Fugiat.
          </p>
        </div>
      </div>
    </section>
    <section class="banner cta">
      <div class="col">
        <h2>Get in touch</h2>
      </div>
      <div class="col">
        <a href="tel: +41 041 541 91 00">+41 041 541 91 00</a>
      </div>
      <div class="col">
        <a href="mailto: info@segetis.ch">info@segetis.ch</a>
      </div>
    </section>
    <section>
      <div class="col">
        <div class="text">
          <h2>
            Services
          </h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis dolore odit enim blanditiis, eius dolores quidem quasi tempore rem. Quibusdam velit necessitatibus quas. Quasi architecto ipsam temporibus facilis rem voluptate.
          </p>
          <div class="button" routerLink="/services">
            <div class="button-text">
              <div class="one">
                Unsere Services
              </div>
              <div class="two">
                entdecken
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col">
        <div class="image">
          <img src="assets/img/segetis_services_general_assembly.jpg" alt="">
        </div>
      </div>
    </section>
  </article>
</main>
