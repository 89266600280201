<main>
  <header>
    <div class="line"></div>
    <h1>
      Kontakt
    </h1>
    <h2>
      Wir sind für Sie da
    </h2>
  </header>
  <article>
    <section>
      <div class="col">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d695235.7396180166!2d7.814276665624992!3d47.103685100000014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ffe7d85a8ae2f%3A0xf4681ccbdff8fd32!2sSegetis%20AG!5e0!3m2!1sen!2sch!4v1611530604676!5m2!1sen!2sch"  frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
      <div class="col">
        <div class="text">
          <p>
          Haben Sie Fragen? Zögern Sie nicht, uns zu kontak­tieren. Sie erreichen uns per Post, Telefon, Fax oder E-Mail.
        </p>
        <p>
          <b>Segetis AG Investor</b>
          Relationship Management
        </p>
        <p>
          Platz 4<br>
          6039 Root D4<br>
          Schweiz
        </p>
        <p>
          <a href="tel: +41 41 541 91 00">+41 41 541 91 00</a>
        </p>
        <p>
          <a href="tel: +41 41 541 91 01">+41 41 541 91 01</a>
        </p>
        <p>
          <a href="mailto: info@segetis.ch">info@segetis.ch</a>
        </p>
        </div>
      </div>
    </section>
    <section class="banner quote">
      <h2 class="quote">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus laboriosam quia.
      </h2>
      <div class="author">
        Hans Mustermann, CEO Mustermann AG
      </div>
    </section>
    <section id="further-reading" class="banner">
      <h4>
        Schon angeklickt?
      </h4>
      <div class="links">
        <a class="link left" routerLink="/services">
        <h2>
          Services
        </h2>
        <div class="background-image">
          <img src="assets/img/segetis_home_services.jpg" alt="">
        </div>
      </a>
      <a class="link middle" routerLink="/software">
        <h2>
          Software
        </h2>
        <div class="background-image">
          <img src="assets/img/segetis_home_software.jpg" alt="">
        </div>
      </a>
      <a class="link right" routerLink="/about">
        <h2>
          Unternehmen
        </h2>
        <div class="background-image">
          <img src="assets/img/segetis_home_about.jpg" alt="">
        </div>
      </a>
      </div>
    </section>
  </article>

</main>
