import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { HeaderService } from '../../../projects/header/src/lib/header.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.styl'],
})
export class LinksComponent implements OnInit {
  links: any;

  constructor(
    private header: HeaderService,
    private contentful: ContentfulService
  ) {}

  ngOnInit(): void {
    this.contentful.getCollectionByName('links', 2).then((links) => {
      console.log(links);
      this.links = links.fields.links;
      console.log(this.links);
    });

    this.header.setHeaderClasses('small');
  }
}
